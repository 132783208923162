export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [37,[2]],
		"/(app)/admin": [38,[2,3]],
		"/(app)/admin/data-sync": [39,[2,3]],
		"/(app)/admin/docket-types": [40,[2,3,4]],
		"/(app)/admin/docket-types/[id]": [41,[2,3,4]],
		"/(app)/admin/filing-types": [42,[2,3,5]],
		"/(app)/admin/filing-types/[id]": [43,[2,3,5]],
		"/(app)/admin/hearing-types": [44,[2,3,6]],
		"/(app)/admin/hearing-types/[id]": [45,[2,3,6]],
		"/(app)/admin/lovs": [46,[2,3,7]],
		"/(app)/admin/lovs/[id]": [47,[2,3,7]],
		"/(app)/admin/organizations": [48,[2,3,8]],
		"/(app)/admin/organizations/[id]": [49,[2,3,8]],
		"/(app)/admin/permissions": [50,[2,3,9]],
		"/(app)/admin/text-templates": [51,[2,3,10]],
		"/(app)/admin/text-templates/[id]": [52,[2,3,10]],
		"/(app)/admin/users": [53,[2,3,11]],
		"/(app)/admin/users/[id]": [54,[2,3,11]],
		"/(app)/admin/users/[id]/positions/[positionId]": [55,[2,3,11,12]],
		"/(app)/admin/workflows": [56,[2,3,13]],
		"/(app)/admin/workflows/[id]": [57,[2,3,13]],
		"/(app)/case-management": [58,[2,14]],
		"/(app)/case-management/assignments": [66,[2,14]],
		"/(app)/case-management/costs": [67,[2,14,16]],
		"/(app)/case-management/new-case": [68,[2,14,17]],
		"/(app)/case-management/scheduling": [69,[2,14,18]],
		"/(app)/case-management/scheduling/sessions/[id]": [70,[2,14,18,19]],
		"/(app)/case-management/[id]": [59,[2,14,15]],
		"/(app)/case-management/[id]/claims/[claimId]": [60,[2,14,15]],
		"/(app)/case-management/[id]/costs/[costId]": [61,[2,14,15]],
		"/(app)/case-management/[id]/filings/new": [63,[2,14,15]],
		"/(app)/case-management/[id]/filings/[filingId]": [62,[2,14,15]],
		"/(app)/case-management/[id]/judgments/[judgmentId]": [64,[2,14,15]],
		"/(app)/case-management/[id]/participants/[participantId]": [65,[2,14,15]],
		"/(public)/confirm-email": [89,[36]],
		"/(app)/financial": [71,[2,20]],
		"/(app)/financial/coa": [72,[2,20,21]],
		"/(app)/financial/cost-schedule": [73,[2,20]],
		"/(app)/financial/disbursements": [74,[2,20,22]],
		"/(app)/financial/disbursements/adhoc": [76,[2,20,22]],
		"/(app)/financial/disbursements/reconciliation": [77,[2,20,22,24]],
		"/(app)/financial/disbursements/reconciliation/[id]": [78,[2,20,22,24,25]],
		"/(app)/financial/disbursements/[id]": [75,[2,20,22,23]],
		"/(app)/financial/receipt-sessions": [79,[2,20,26]],
		"/(app)/financial/receipt-sessions/[id]": [80,[2,20,26,27]],
		"/(app)/financial/receipts": [81,[2,20,28]],
		"/(app)/financial/receipts/new": [83,[2,20,28,30]],
		"/(app)/financial/receipts/[id]": [82,[2,20,28,29]],
		"/(app)/financial/reports": [84,[2,20,31]],
		"/(app)/financial/transactions": [85,[2,20,32]],
		"/(app)/financial/transactions/[id]": [86,[2,20,32,33]],
		"/(public)/forgot-password": [90,[36]],
		"/(public)/login": [91,[36]],
		"/(public)/reset-password": [92,[36]],
		"/(app)/support/agent": [87,[2,34]],
		"/(app)/support/report-instances": [88,[2,35]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';